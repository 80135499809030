<template>
  <!--  // TODO: Добавить лоадер-->
  <!--  v-loading="!isDataLoadedSales"-->
  <div
    class="sales-users-wrapper page"
  >
    <select-user
      :defaultSelectedUser="selectedUser"
      @select="selectUser"
      @change="clearData"
    />

<!--  TODO: Отказаться от использования Vuetify  -->
    <v-expansion-panels
      v-if="selectedUser"
      v-model="openedPanel"
    >
      <v-expansion-panel
        v-for="item in getDataWorkingShift"
        :key="item.id"
        @click="loadSalesById(item.id)"
      >
        <v-expansion-panel-header>
          {{ item.openingAt }} - {{ item.closingAt }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <el-card class="box-card box-control box-control-flex box-control-flex-column">
            <sale-data
              v-if="getWorkingShiftData.countSales"
              :props-data="getWorkingShiftData"
            />
          </el-card>

          <data-table
            v-if="isShowDataTable"
            class="mt-4"
            :data="renderData"
            remove-height
            hide-update
          />
          <span v-if="isShowNoResultText">Нет данных</span>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DataTable from '@/components/dataTable'
import SelectUser from '@/components/salesUsers/selectUser'
import SaleData from '@/components/salesUsers/saleData'

import moment from 'moment'
import isInteger from 'lodash/isInteger'
import { getPriceToFormat } from '@/helper'

import tableMethods from '@/mixins/tableMethods'
import page from '@/mixins/page'

export default {
  name: 'salesUsers',
  data () {
    return {
      selectedUser: '',
      loadedSailsData: [],
      openedPanel: null
    }
  },
  components: {
    DataTable,
    SelectUser,
    SaleData
  },
  mixins: [tableMethods, page],
  computed: {
    ...mapGetters('sales', { getDataSales: 'getData', isDataLoadedSales: 'getIsDataLoaded' }),
    ...mapGetters('workingShift', { getDataWorkingShift: 'getData' }),
    ...mapGetters('users', { getDataUsers: 'getData' }),
    ...mapGetters('settings', ['getDefaultValues']),
    ...mapGetters('info', ['getWorkingShiftData']),
    renderData() {
      return this.loadedSailsData.map(item => {
        return {
          ...item,
          typePay: this.getDefaultValues?.typePay?.filter(el => el.code === item.typePay)[0]?.name,
          renderPrice: `${ getPriceToFormat(item.price) } р.`,
          createdAt: moment(item.createdAt).format('dd - DD.MM.YYYY HH:mm')
        }
      })
    },
    isShowDataTable () {
      return this.renderData.length && this.isDataLoadedSales
    },
    isShowNoResultText () {
      return this.isDataLoadedSales && !this.renderData.length
    }
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('users', { loadDataUsers: 'loadData' }),
    ...mapActions('workingShift', { loadDataWorkingShift: 'loadData' }),
    ...mapActions('sales', ['getDataByWorkingShiftAndUserId']),
    ...mapActions('info', ['loadWorkingShiftData']),
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadDataUsers()
      await this.loadDataWorkingShift()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
    selectUser (userId) {
      this.selectedUser = userId
    },
    clearData () {
      this.openedPanel = null
      this.loadedSailsData = []
    },
    async loadSalesById (id) {
      this.loadedSailsData = []

      await this.$nextTick()

      if (!isInteger(this.openedPanel)) {
        return
      }

      this.loadedSailsData = await this.getDataByWorkingShiftAndUserId({
        userId: this.selectedUser,
        workingShiftId: id
      }) || []

      await this.loadWorkingShiftData({
        userId: this.selectedUser,
        workingShiftId: id,
        force: true
      })
    }
  }
}
</script>

<style lang="scss" src="./style.scss" />