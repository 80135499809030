<template>
  <div>
    <div
      v-for="item in LIST"
      :key="item.key"
    >
      {{ item.name }}: {{ renderData[item.key] }}
    </div>
  </div>
</template>

<script>
const LIST = [
  {
    name: 'Кол. продаж',
    key: 'countSales'
  },
  {
    name: 'Среднее кол. товаров в чеке',
    key: 'averageCountProducts'
  },
  {
    name: 'Общая прибыль',
    key: 'totalProfit'
  },
  {
    name: 'Чистая прибыль',
    key: 'netProfit'
  },
  {
    name: 'Средняя сумма продаж',
    key: 'averageSumSales'
  },
  {
    name: 'Оплата налчными',
    key: 'payCash.'
  },
  {
    name: 'Оплата картой',
    key: 'payTerminal'
  },
  {
    name: 'Оплата переводом',
    key: 'payTranslation'
  }
]

export default {
  name: 'selectUser',
  props: {
    propsData: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      LIST
    }
  },
  computed: {
    renderData () {
      return {
        countSales: this.propsData.countSales.value || 0,
        averageCountProducts: this.propsData.averageCountProducts || 0,
        totalProfit: this.propsData.totalProfit.value || 0,
        netProfit: this.propsData.netProfit.value || 0,
        averageSumSales: this.propsData.averageSumSales || 0,
        payCash: this.propsData.pay.cash || 0,
        payTerminal: this.propsData.pay.terminal || 0,
        payTranslation: this.propsData.pay.translation || 0
      }
    }
  }
}
</script>
