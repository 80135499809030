<template>
  <el-card class="box-card box-control box-control-flex box-control-flex-column">
    <label
      class="main-label"
    >
      <span class="main-label__title">Выберите сотрудника</span>

      <el-select
        v-model="selectedUser"
        filterable
        collapse-tags
        placeholder="Выберите сотрудника"
        @input="select"
        @change="change"
      >
        <el-option
          v-for="item in getData"
          :key="item.value"
          :label="item.name"
          :value="item.id"
        />
      </el-select>
    </label>
  </el-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'selectUser',
  props: {
    defaultSelectedUser: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      selectedUser: this.defaultSelectedUser,
    }
  },
  computed: {
    ...mapGetters('users', ['getData']),
  },
  methods: {
    change () {
      this.$emit('change')
    },
    select () {
      this.$emit('select', this.selectedUser)
    }
  }
}
</script>
